import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import YotpoStars from 'src/components/YotpoStars'
import { graphql, useStaticQuery } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import {
  Container,
  TextBox,
  Text,
  CenteredOnMobile,
  Section,
  VideoContainer,
  HeroVideo,
  SubHeader,
  BuyNowLink,
  Item,
  ListItem,
  CheckMark,
  BuyNowAnchor,
  ExternalLink,
  Subheading,
} from './styles'
import {
  BUY_LINK,
  IN_AU,
  IN_CA,
  IN_EU,
  IN_IE,
  IN_UK,
  IS_FOREIGN_COUNTRY,
  LOCALIZATION,
} from '../../utils/constants'
import heroVideo from '../../videos/frame-video.mp4'
import Analytics from '../../utils/Analytics'
import { Intro } from '../CalMaxHero/styles'

const Hero = ({ page, title, subheading, ctaText }) => {
  const { t } = useTranslation('common')

  const { check, link, headings } = useStaticQuery(graphql`
    query HeroQuery {
      check: file(relativePath: { eq: "checkmark.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      link: file(relativePath: { eq: "external-link.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      headings: allContentfulHomepageHero {
        edges {
          node {
            es_heading
            fr_heading
            nl_heading
            it_heading
            de_heading
          }
        }
      }
    }
  `)

  const getAmazonLink = () => {
    if (IN_UK) return 'https://urlgeni.us/amazon/shop-amz-uk'
    if (IN_CA) return 'https://urlgeni.us/amazon/shop-amz-ca'
    if (IN_AU)
      return 'https://www.amazon.com.au/Skylight-Frame-Digital-Picture-Anywhere/dp/B01N7ENHO6'

    return 'https://urlgeni.us/amazon/shoptraffic'
  }

  const AMAZON_LINK = getAmazonLink()

  const videoSrc = heroVideo
  const [videoControls, setVideoControls] = useState(false)

  useEffect(() => {
    if (
      window.navigator.userAgent.indexOf('Mozilla') !== -1 &&
      window.navigator.userAgent.indexOf('Android') !== -1
    ) {
      setVideoControls(true)
    }
  }, [])

  const itemList = [
    t('No app or subscription required'),
    t('Quick 1-minute setup — easy for all ages'),
    t('Effortless touchscreen display'),
  ]
  const headingsData = headings.edges[0].node
  const country = `${LOCALIZATION.replace(/-/g, '_')}_heading`

  const heroContent = {
    isFramePage: {
      eyebrow: 'The gift that keeps loved ones connected.',
      heading: 'Skylight Digital Picture Frame',
      description:
        'Skylight is a digital picture frame you can send photos to, and they appear in seconds!',
    },
    isEnglishCalAndFrame: {
      eyebrow: 'Skylight Calendar & Frame',
      heading: 'Keeping Families Connected',
      description:
        'Everyone’s on the same page (all the time!) with shareable photos, schedules and more.',
    },
    isEnglishFrameOnly: {
      eyebrow: 'Skylight Frame',
      heading: 'Keeping Families Connected',
      description:
        'Everyone’s on the same page (all the time!) with shareable photos, schedules and more.',
    },
    isForeignLang: {
      eyebrow: 'Buy the #1 Gift of the Season',
      heading: headingsData?.[country],
      description:
        'Bring your family together with the Skylight digital picture Frame. The perfect gift this holiday season.',
    },
  }

  let pageType

  if (IN_EU) {
    pageType = 'isEnglishCalAndFrame'
  } else if (IN_IE) {
    pageType = 'isEnglishFrameOnly'
  } else if (IS_FOREIGN_COUNTRY && !IN_AU && !IN_CA && !IN_UK) {
    pageType = 'isForeignLang'
  } else {
    pageType = 'isFramePage'
  }

  const { eyebrow } = heroContent[pageType]
  const heading = title || heroContent[pageType].heading
  const { description } = heroContent[pageType]

  return (
    <Section>
      <Container>
        <TextBox>
          <YotpoStars textcolor="#444444" linkUrl={BUY_LINK} />
          <Intro>{t(eyebrow)}</Intro>
          <Text>{title || heading}</Text>
          <Subheading>{t(description)}</Subheading>
          {pageType === 'isFramePage' &&
            itemList.map((item, i) => (
              <Item key={i}>
                <CheckMark image={check.childImageSharp.gatsbyImageData} alt="check mark" />
                <ListItem>{item}</ListItem>
              </Item>
            ))}
          {subheading && <SubHeader>{subheading}</SubHeader>}
          {page === 'amazon' ? (
            <>
              <CenteredOnMobile>
                <BuyNowAnchor
                  href={AMAZON_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => Analytics.track('Clicked to Amazon')}
                >
                  {ctaText || t('Buy Now')}{' '}
                  <ExternalLink image={link.childImageSharp.gatsbyImageData} alt="external link" />
                </BuyNowAnchor>
              </CenteredOnMobile>
              <CenteredOnMobile>
                <BuyNowLink
                  to={BUY_LINK}
                  onClick={() => Analytics.track('Clicked Buy Now on Frame Amazon Landing Page')}
                >
                  Buy Now
                </BuyNowLink>
              </CenteredOnMobile>
            </>
          ) : (
            <CenteredOnMobile>
              <BuyNowLink to={BUY_LINK}>{ctaText || t('Buy Now')}</BuyNowLink>
            </CenteredOnMobile>
          )}
        </TextBox>
        <VideoContainer>
          <HeroVideo playsInline autoPlay muted loop controls={videoControls}>
            <source src={videoSrc} type="video/mp4" />
          </HeroVideo>
        </VideoContainer>
      </Container>
    </Section>
  )
}

Hero.propTypes = {
  page: PropTypes.string,
  title: PropTypes.string,
  subheading: PropTypes.string,
  ctaText: PropTypes.string,
}
export default Hero
