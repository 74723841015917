import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import {
  FeatureContainer,
  Header,
  Container,
  Card,
  ContentContainer,
  ImageCard,
  SecondaryHeader,
  Text,
} from './styles'

function Content(props) {
  const { headingOne, text, i } = props
  return (
    <Card key={i}>
      <SecondaryHeader>{headingOne}</SecondaryHeader>
      <Text>{text}</Text>
    </Card>
  )
}

const FrameFeatures = ({ title, features }) => {
  const { t } = useTranslation('common')

  const { delight1, delight2, delight3, delight4, delight5, delight6 } = useStaticQuery(graphql`
    query FrameFeaturesQuery {
      delight1: file(relativePath: { eq: "redesign/delight-1.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      delight2: file(relativePath: { eq: "redesign/delight-2.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      delight3: file(relativePath: { eq: "redesign/delight-3.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      delight4: file(relativePath: { eq: "redesign/delight-4.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      delight5: file(relativePath: { eq: "redesign/delight-5.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      delight6: file(relativePath: { eq: "redesign/delight-6.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const defaultPictures = [
    { image: delight1, alt: 'Picture of girl jumping into the ocean on a skylight picture frame' },
    {
      image: delight2,
      alt: 'Iphone showing the photo gallary in front of a newly uploaded blue eyed baby onto the skylight frame',
    },
    {
      image: delight3,
      alt: 'A customer touching the skylight frame which displays two children playing on grass',
    },
    {
      image: delight4,
      alt: 'A portrait skylight frame where user clicks on the notification for new photos arriving to the frame',
    },
    { image: delight5, alt: 'A picture of a dog owner hugging their dog on the skylight frame' },
    { image: delight6, alt: 'A picture of a mom and daughter on the skylight frame' },
  ]

  const pictures = defaultPictures
  return (
    <FeatureContainer color="#dcf1f3">
      <Header>{title || t('Designed to delight')}</Header>
      <Container>
        {features.map((content, i) => {
          const data = (
            <Content
              headingOneLine={t(content.headingOneLine)}
              headingOne={t(content.headingOne)}
              headingTwo={t(content.headingTwo)}
              text={t(content.text)}
              i={i}
            />
          )
          return (
            <ContentContainer reverse={i % 2 === 0}>
              <ImageCard>
                <GatsbyImage
                  image={pictures[i].image.childImageSharp.gatsbyImageData}
                  alt={pictures[i].alt}
                />
              </ImageCard>
              {data}
            </ContentContainer>
          )
        })}
      </Container>
    </FeatureContainer>
  )
}

FrameFeatures.propTypes = {
  title: PropTypes.string,
  features: PropTypes.array.isRequired,
}

Content.propTypes = {
  headingOne: PropTypes.string,
  text: PropTypes.string,
  i: PropTypes.number,
}

export default FrameFeatures
