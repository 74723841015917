import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const FeatureContainer = styled.div`
  padding: 80px 0px;
  background-color: ${(props) => props.color};
  @media (max-width: ${breakpoints.xl}px) {
    padding: 40px 0px 10px;
  }
`
export const Container = styled.div`
  width: 90%;
  margin: auto;
`
export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin: 40px 0px;
  > :first-child {
    order: ${(props) => (props.reverse ? '1' : '2')};
  }
  > :last-child {
    order: ${(props) => (props.reverse ? '2' : '1')};
  }
  @media (max-width: ${breakpoints.m}px) {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-flow: row;
    gap: 16px;
    > :first-child {
      order: 1;
    }
    > :last-child {
      order: 2;
    }
  }
`
export const Header = styled.h2`
  font-family: 'P22MackinacProBook';
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: ${(props) => props.theme.grayDark};
  margin: 0px auto 40px;
  max-width: 900px;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 26px;
    line-height: 32px;
  }
`
export const SecondaryHeader = styled.h3`
  font-family: 'P22MackinacProBook';
  font-size: 26px;
  line-height: 32px;
  color: ${(props) => props.theme.grayDark};
  margin: 0px;
  font-weight: normal;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 26px;
    line-height: 1.2;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 20px;
    line-height: 32px;
    text-align: center;
  }
`
export const Text = styled.p`
  font-family: 'FilsonProBook';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  color: ${(props) => props.theme.grayDark};
  max-width: 400px;
  @media (max-width: ${breakpoints.l}px) {
    max-width: 100%;
    margin: 0px;
  }
  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
  }
`
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ImageCard = styled.div`
  width: 100%;
  img {
    border-radius: 25px;
  }
`
